<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(product_unit)="data">
          {{ getCodeLabel('product_unit', data.item.product_unit) }}
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import purchaseinboundorderitemUseList from './purchaseinboundorderitemUseList'
import purchaseinboundorderitemStore from './purchaseinboundorderitemStore'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('purchaseinboundorderitem/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  props: {
    in_order_id: {
      type: Number,
      default: 0
    },
  },
  setup(props) {
    // Register module
    if (!store.hasModule('purchaseinboundorderitem')) store.registerModule('purchaseinboundorderitem', purchaseinboundorderitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseinboundorderitem')) store.unregisterModule('purchaseinboundorderitem')
    })

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = purchaseinboundorderitemUseList(
            {
              in_order_id: props.in_order_id,
            }
    )

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
